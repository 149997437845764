import { Link } from "gatsby"
import React, {useState} from "react"
import LOGO_NORMAL from "../images/Logo-wedo.png";
import NavMenu from "./menu-nav";
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from "@reach/router";
import { useEffect } from "react";
import {cleanDevelopmentAction} from "../redux/developmentsDucks";
import ReactGA from 'react-ga4';
ReactGA.initialize('G-VT90V60LVD');

import { connect } from "react-redux";

function Main({dispatch, siteTitle, logo}) {
    
    const [open,setOpen] = useState(false);
    const location = useLocation()

    useEffect(() => {
        if(!location.pathname.includes('/emprendimientos')){
            dispatch(cleanDevelopmentAction())
        }
    },[location])

    const staticBody = () => {
        var body = document.body;
        body.classList.toggle("overflow-hidden");
    }

    return(
    <header id="header" className={"blur " + (open ? 'opened' : '')}>

        <Toaster
        toastOptions={{
            className: 'toast-className',
            style: {
                maxWidth: 'fit-content',
              },
        }} />
        <nav className="brand">
            <div className="container-fluid">
                <div className="row justify-content-center align-items-center">
                    <div className="col-6 justify-content-start">
                        <Link to={"/"} className="logo d-none d-lg-block">
                            <img className="logo-header" src={LOGO_NORMAL} alt="" />
                            {/* <img className="logo-header" src={logo}></img> */}
                        </Link>
                        <Link to={"/"}  className="logo d-lg-none">
                            <img className="logo-header" src={LOGO_NORMAL} alt="" />
                            {/* <img className="logo-header" src={logo}></img> */}
                        </Link>
                    </div>
                    <div className="col-6 text-right d-flex justify-content-end">
                        <Link className="me-4 icon-favorites d-flex align-items-center justify-content-center" to={"/favoritos"}><i className={"icon-like " + (location.pathname.includes("favoritos") ? 'active' : '')}></i> </Link>
                        <button id="toggle-nav" onClick={() => setOpen(!open) + staticBody()}>
                            <div className="d-flex align-items-center"> 
                                <span className="status_nav d-none d-lg-inline">MENÚ </span> 
                                <span className="ms-4"><i></i><i></i><i></i></span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </nav>
        <NavMenu setOpen={setOpen} />
  </header>
)}

export default connect(state => ({

}),null)(Main);