import React, {useEffect} from "react";
import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import toast from 'react-hot-toast';
import {existOperation,existDevelopment, getMessageWhatsapp, getPhonesText, makeItemsNav} from '../helpers/helper.rendering'
import { useLocation } from '@reach/router';

import {newSletterTokko,updateFields} from '../redux/contactDucks'

import { Helmet } from "react-helmet"
import LOGO from '../images/Logo-wedo-footer.png'

const Footer = ({filters,api_key,developments,development,dispatch}) => {

  const location = useLocation()
  const pathname = location.pathname.split("/")[1]

  const [open,setOpen] = useState(false)
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        logo
        global_email
        fiscal_data
        bot_cliengo
        global_whatsapp
        global_phone
        global_broker_id
        keys{
            captcha
        }
        social{
          facebook
          instagram
          linkedin
          youtube
          twitter
        }
        branch_office{
          name
          address
          city
          region
          broker_id
          contact {
            phone
            mail
            whatsapp
          }
        }
        sections {
          footer {
            title_contact
            title_social
            title_newsletter
            title_office
            button_contact {
                link
                value
            }
          }
          about{
            team{
              items{
                name
              }
            }
          }
        }
      }
  }`) 
  const [disabled,setDisabled] = useState(true)
  const [datos, setDatos] = useState({
    email: "",
  });
  const currentYear = new Date().getFullYear();
  const handleInputChange = (e) => {
      setDatos({
        ...datos,
        [e.target.name]: e.target.value,
      });
  };
  const sendForm = (e) => {
    e.preventDefault();
    dispatch(newSletterTokko())
    document.getElementById("formNewsletter").reset();
    return toast.success("Te suscribiste a nuestro newsletter correctamente.") 
  }
  // Función que devuelve el año actual
  const getCurrentYear = () => {
    const date = new Date().getFullYear()
    return date;
  }
    
  useEffect(() => {
    const update = () => {
        dispatch(updateFields(datos));
    };
    update();
    if(!realEstate?.keys?.captcha){
      setDisabled(false)
    }
    else{
        setDisabled(true)
    }
  }, [datos]);
    return realEstate.sections ? (
      <>
        <Helmet>
        {/* <script type="text/javascript"> */}
           {
             realEstate.bot_cliengo ? 
              (function() {
                    var ldk = document.createElement('script');
                    ldk.type = 'text/javascript';
                    ldk.async = true;
                    ldk.src = realEstate.bot_cliengo;
                    var s = document.getElementsByTagName('script')[0];
                    s.parentNode.insertBefore(ldk, s);
                })()
              :
                ''
            }
        {/* </script> */}
        </Helmet>
        <div className="sup-footer">
            <div className="container-fluid">
              <div class="fixed-bar position-fixed">
                  <div>
                    <a href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+54" + realEstate.branch_office[0].contact.whatsapp.replaceAll("-",'')} target="_blank" class={"branch-btn contact-btn d-none d-lg-flex " + (open ? 'open' : ' ')}>{realEstate.branch_office[0].region}</a>
                    <a href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+54" + realEstate.branch_office[1].contact.whatsapp.replaceAll("-",'')} target="_blank" class={"branch-btn contact-btn d-none d-lg-flex " + (open ? 'open' : ' ')}>{realEstate.branch_office[1].region}</a>
                    <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+54" + realEstate.branch_office[0].contact.whatsapp.replaceAll("-",'')} target="_blank" class={"branch-btn contact-btn d-flex d-lg-none " + (open ? 'open' : ' ')}>{realEstate.branch_office[0].region}</a>
                    <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+54" + realEstate.branch_office[1].contact.whatsapp.replaceAll("-",'')} target="_blank" class={"branch-btn contact-btn d-flex d-lg-none " + (open ? 'open' : ' ')}>{realEstate.branch_office[1].region}</a>
                  </div>
                  <a onClick={() => setOpen(!open)} class="icon-whatsapp-o contact-btn d-flex"></a>
              </div>
              {/* <div class={"fixed-bar position-fixed " + (realEstate.global_whatsapp === '' && 'd-none')}>
                  <a href={"https://web.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+54" + realEstate.global_whatsapp.replaceAll("-",'')} target="_blank" class={"icon-whatsapp-o contact-btn d-none d-lg-flex "}></a>
                  <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp(development) + "&phone=+54" + realEstate.global_whatsapp.replaceAll("-",'')} target="_blank" class={"icon-whatsapp-o contact-btn d-flex d-lg-none "}></a>
              </div> */}
              <div className="row">
                <div className="col-12">
                    <div className="row">
                      <div className="col-lg-4 order-1">
                        <div className="item-contact row">
                            <h5 className="col-lg-12 col-4">{realEstate?.sections?.footer?.title_contact}</h5>
                            {realEstate?.global_phone.map((phone,index) => (
                                <p className="mb-2">Telefono {phone}</p>
                            ))}
                            <div className="col-lg-12 col-8">
                              <a target="_blank" className="hover-footer-link mb-4" href={"mailto:"+realEstate?.global_email}>{realEstate?.global_email}</a>
                              <Link className="d-none d-lg-none btn btn-tertiary mt-5" to={realEstate?.sections?.footer?.button_contact.link}>{realEstate?.sections?.footer?.button_contact.value}</Link>
                              <Link className="btn btn-tertiary d-lg-inline-block d-none mt-lg-0" to={realEstate?.sections?.footer?.button_contact?.link}>{realEstate?.sections?.footer.button_contact.value}</Link>
                              {realEstate?.global_broker_id.map((broker,index) => (
                                <p className="mb-2">Matrícula {broker}</p>
                              ))}
                             
                            </div>
                        </div>
                      </div>
                      <div className="col-lg-4 order-2">
                        {realEstate.branch_office.length > 0 &&
                          realEstate.branch_office.map((branch,index) => (
                                <div className={"item-contact align-items-start row " + (index > 0 ? 'd-lg-none d-block' : 'd-block')}>
                                    <h5  className="col-lg-12 col-4">{realEstate.sections?.footer?.title_office} {" "} {branch.region}</h5>
                                    <div className="col-lg-12 col-8">
                                      <p >{branch.name}</p>
                                      <p >{branch.address}</p>
                                      <p >{branch.city}</p>
                                      {branch.contact.phone.map(phone => (
                                        <p >Tel: {phone}</p>
                                      ))}
                                    </div>
                                </div>
                          ))
                        }
                        {
                          <div className={"item-contact row d-block d-lg-none" }>
                            <h5  className="col-lg-12 col-4">MATRÍCULAS</h5>
                            <div className="col-lg-12 col-8">
                              <p >CUCICBA 6605 </p>
                              <p >CMCPSI 5827</p>
                            </div>
                          </div>
                    
                        }
                      </div>
                      <div className="col-lg-4 pl-lg-4 order-3">
                        <div className="content-contact mt-auto">
                          <h4 >{realEstate.sections?.footer?.title_newsletter}</h4>
                          <form onSubmit={sendForm} id="formNewsletter" >
                            <div className="d-flex mb-lg-3">
                                <input type="email" onChange={handleInputChange} name="email" required placeholder="Ingresá tu mail" className="mr-2" />
                                <button
                                disabled={disabled}
                                type="submit" className="submit icon-arrow-right"></button>
                            </div>
                            {
                                realEstate?.keys?.captcha ? 
                                <ReCAPTCHA
                                sitekey={realEstate?.keys?.captcha ? realEstate?.keys?.captcha : 0}
                                onChange ={() => setDisabled(false)}
                                /> 
                                : 
                                '' 
                            }
                          </form>
                        </div> 
                      </div>
                  </div>
                </div>
                <div className="col-lg-4 my-5 my-lg-0 d-none d-lg-block">
                  <div className="item-contact row mb-0">
                    <h5  className="col-lg-12 col-4">{realEstate?.sections?.footer?.title_social}</h5>
                    <div className="col-lg-12 col-8">
                      <a target="_blank" href={realEstate.social.instagram} class={realEstate.social.instagram ? "d-inline" : 'd-none'}><i class="icon-instagram"></i></a>
                      <a target="_blank" href={realEstate.social.facebook} class={realEstate.social.facebook ? "d-inline" : 'd-none'}><i class="icon-facebook"></i></a>
                      <a target="_blank" href={realEstate.social.linkedin} class={realEstate.social.linkedin ? "d-inline" : 'd-none'}><i class="icon-linkedin"></i></a>
                      <a target="_blank" href={realEstate.social.youtube} class={realEstate.social.youtube ? "d-inline" : 'd-none'}><i class="icon-youtube"></i></a>
                      <a target="_blank" href={realEstate.social.twitter} class={realEstate.social.twitter ? "d-inline" : 'd-none'}><i class="icon-twitter"></i></a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 d-none d-lg-block">
                  {realEstate.branch_office.length > 0 &&
                    realEstate.branch_office.map((branch,index) => (
                          <div className={"item-contact row " + (index > 0 ? 'd-block' : 'd-lg-none d-block')}>
                            <h5  className="col-lg-12 col-4">{realEstate.sections?.footer?.title_office} {" "} {branch.region}</h5>
                            <div className="col-lg-12 col-8">
                              <p >{branch.name}</p>
                              <p >{branch.address}</p>
                              <p >{branch.city}</p>
                              {branch.contact.phone.map(phone => (
                                <p >Tel: {phone}</p>
                              ))}
                            </div>
                          </div>
                    ))
                  }
                </div>  
                <div className="col-lg-4 d-none d-lg-block">
                  {realEstate.branch_office.length > 0 &&
                    realEstate.branch_office.map((branch,index) => (
                          <div className={"item-contact row " + (index > 0 ? 'd-block' : 'd-lg-none d-block')}>
                            <h5  className="col-lg-12 col-4">MATRÍCULAS</h5>
                            <div className="col-lg-12 col-8">
                              <p >CUCICBA 6605 </p>
                              <p >CMCPSI 5827</p>
                            </div>
                          </div>
                    ))
                  }
                </div>
              </div>
            </div>      
        </div>
        <footer>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-8 col-lg-3 d-flex d-lg-block align-items-start flex-column">
                      <div className="logo-footer">
                        <Link to=""><img src={LOGO}></img></Link> 
                      </div>
                      <div className="d-block d-lg-none mt-auto">
                          
                        <div className="d-flex align-items-start">
                          <p className="copy">
                            Design by <a className="mh" href="https://mediahaus.com.ar">MediaHaus</a> - Powered by <a href="https://mediacore.com.ar/app"> MediaCore</a> <br /> <span className="reservated">Todos los derechos reservados &reg; {currentYear} </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 col-lg-6 offset-lg-3 d-lg-flex align-items-center justify-content-between">
                      {makeItemsNav(developments,filters).map((item,index) => (
                                <li className={(pathname === item.link.replaceAll("/","").toLowerCase() ? 'active ' : '')}>
                                    {
                                        item.blank ? 
                                            <a target="_blank" className="" href={item.link}>{item.name}</a>
                                        :
                                            <Link className="" to={item.link}>{item.name}</Link>
                                    }
                                </li>
                      ))}
                    </div>
                </div>
                <div className="row mt-lg-3 py-lg-5 d-none d-lg-flex justify-content-between align-items-end">
                    <div className="col-12 align-items-start">
                      <p className="copy">
                        Design by <a className="mh" href="https://mediahaus.com.ar">MediaHaus</a> - Powered by <a href="https://mediacore.com.ar/app"> MediaCore</a> <br /> <span className="reservated">Todos los derechos reservados &reg; {currentYear} </span>
                      </p>
                    </div>
                    
                </div>
            </div>
        </footer>
      </>
    ):''
}

export default connect(state => ({
  filters:state.properties.totalListFilters,
  api_key:state.settings.keys.tokko,
  developments:state.developments.developments,
  development:state.developments.development
}),null)(Footer);